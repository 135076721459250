import React from 'react';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/arrow-next.svg';
import { ReactComponent as UtairIcon } from 'shared/assets/icons/logo-u-rectangle.svg';

import styles from './PromoBlock.module.scss';

export default function PromoBlock() {
    const mobAppLink = 'https://redirect.appmetrica.yandex.com/serve/749209601169240471';

    return (
        <section className={styles.PromoBlock} data-testid="PromoBlock">
            <h1 className={styles.metaTitle}>Билеты на самолёты авиакомпании «ЮТэйр»</h1>
            <div className={styles.Content}>
                <p className={styles.Header}>Распродажа до -50%</p>
                <p className={styles.Description}>Скидки туда, где тепло</p>
                <div className={styles.Links}>
                    <a
                        href="https://media.utair.ru/"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.highlightedLink}
                    >
                        Подробнее
                    </a>
                </div>
                <a href={mobAppLink} className={styles.Mobile}>
                    <UtairIcon className={styles.Icon} />
                    <div>
                        <p className={styles.Title}>В мобильном приложении удобнее</p>
                        <span className={styles.Link}>
                            Подробнее <ArrowRight className={styles.ArrowIcon} />
                        </span>
                    </div>
                </a>
            </div>
        </section>
    );
}
